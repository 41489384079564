<template>
  <div class="v-projects-show">
    <router-view />

    <!-- mobile -->
    <portal v-if="mq['sm-']" to="footer">
      <BottomNavigation />
    </portal>
  </div>
</template>

<script>
import { WithViewport } from '@/mixins';
import { ViewWithProject } from '@/views/mixins';
import { guards } from '@/router/routes/projects';

// components
import BottomNavigation from '@/components/ui/BottomNavigation.vue';

export default {
  name: 'VProjectsShow',
  components: { BottomNavigation },
  mixins: [ViewWithProject(true), WithViewport],
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'projects-show' && to.name === from.name)
      return guards.show.beforeEnter(to, from, next);

    next();
  },
  computed: {
    ready() {
      return this.projectReady;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
.v-projects-show
  height: 100%
</style>
