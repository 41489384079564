<template>
  <EButton :to="to" v-bind="bind" v-on="$listeners">video</EButton>
</template>

<script>
export default {
  name: 'ProjectsShowServicesIndexGridLink',
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      to: {
        name: 'projects-show-services-index-grid',
        params: { projectId: this.project.id },
      },
    };
  },
  computed: {
    bind() {
      const bind = { navigation: true, md: true };

      return Object.assign(bind, this.$attrs);
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
