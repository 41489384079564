<template>
  <div class="bottom-navigation">
    <ProjectsShowServicesIndexGridLink :project="project" />
    <ProjectsShowSummaryLink :project="project" />
    <ProjectsShowServicesIndexMapLink :project="project" />
  </div>
</template>

<script>
import { WithProject } from '@/mixins';

// components
import ProjectsShowServicesIndexGridLink from '@/components/collection/projects/ProjectsShowServicesIndexGridLink.vue';
import ProjectsShowServicesIndexMapLink from '@/components/collection/projects/ProjectsShowServicesIndexMapLink.vue';
import ProjectsShowSummaryLink from '@/components/collection/projects/ProjectsShowSummaryLink.vue';

export default {
  name: 'BottomNavigation',
  components: {
    ProjectsShowServicesIndexGridLink,
    ProjectsShowServicesIndexMapLink,
    ProjectsShowSummaryLink,
  },
  mixins: [WithProject()],
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$min-height: 56px

.bottom-navigation
  min-height: $min-height

  +d-flex-r(center, space-around)
  +p-relative(#{$z-index-bottom-drawer + 1})

// color
.bottom-navigation
  background-color: var(--c-background)
</style>
